import {
  mdiAccountCog,
  mdiCardAccountDetails,
  mdiCartVariant,
  mdiFileDocument,
  mdiQualityHigh,
  mdiSignal,
  mdiTabletDashboard,
  mdiTuneVariant,
  mdiViewDashboard,
  mdiWallet,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

const sigma = [
  {
    title: 'DASHBOARD',
    icon: mdiViewDashboard,
    children: [
      {
        title: 'Vendedores',
        to: 'sigma-vendor',
      },
    ],
  },

  {
    subheader: 'Ferramentas do Vendedor',
  },
  {
    title: 'CARTEIRA',
    icon: mdiWallet,
    children: [
      {
        title: 'Clientes',
        to: 'sigma-client',
      },
      {
        title: 'Contratos',
        to: 'sigma-contract',
      },
    ],
  },
  {
    title: 'PÓS-VENDAS',
    icon: mdiCartVariant,
    children: [
      {
        title: 'Assembleia',
        to: 'sigma-assembly',
      },
      {
        title: 'Encerramento',
        to: 'sigma-closing-group',
      },
      {
        title: 'Aniversariante',
        to: 'sigma-birthday-person',
      },
      {
        title: 'Localizar Grupos',
        to: 'sigma-reserve-group',
      },
      {
        title: 'Loteria Federal',
        to: 'sigma-lottery',
      },
    ],
  },

  {
    subheader: 'Ferramentas do Administrativo',
  },
  {
    title: 'ADM CNH',
    icon: mdiCardAccountDetails,
    children: [
      {
        title: 'Integração',
        to: 'sigma-integration',
      },
    ],
  },
  {
    title: 'TERMO LGPD',
    icon: mdiFileDocument,
    children: [
      // {
      //   title: 'Validar',
      //   to: 'sigma-lgpd-validate',
      // },
      // {
      //   title: 'Consultar',
      //   to: 'sigma-lgpd-consult',
      // },
      // {
      //   title: 'Inutilizar',
      //   to: 'sigma-lgpd-disable',
      // },
      {
        title: 'Consultar termos',
        to: 'sigma-lgpd-consult-terms',
      },
    ],
  },

  {
    subheader: 'Ferramentas do Gestor',
  },
  {
    title: 'GESTOR',
    icon: mdiTabletDashboard,
    to: 'under-development',
    children: [
      {
        title: 'Retenção',
        icon: mdiViewDashboard,
        children: [
          {
            title: 'Vendedor',
            to: 'sigma-adm-retention',
          },
          {
            title: 'Mensal',
            to: 'sigma-adm-retention-month',
          },
          {
            title: 'Regional',
            to: 'sigma-adm-retention-region',
          },
          {
            title: 'Regional Mensal',
            to: 'sigma-adm-retention-region-month',
          },
        ],
      },
      {
        title: 'Qualidade',
        icon: mdiQualityHigh,
        children: [
          {
            title: 'Vendedor',
            to: 'sigma-sale-quality-vendor',
          },
          {
            title: 'Mensal',
            to: 'sigma-sale-quality-monthly',
          },
          {
            title: 'Regional',
            to: 'sigma-sale-quality-region',
          },
          {
            title: 'Regional Mensal',
            to: 'sigma-sale-quality-region-monthly',
          },
          {
            title: 'Vendas anuais',
            to: 'sigma-adm-sales-yearly',
          },
        ],
      },
      {
        title: 'Rentabilidade',
        icon: mdiSignal,
        children: [
          {
            title: 'Mensal',
            to: 'sigma-sale-profitability-monthly',
          },
          {
            title: 'Documento',
            to: 'sigma-export-profitability-cpf',
          },
        ],
      },
      {
        title: 'Utilitários',
        icon: mdiAccountCog,
        children: [
          {
            title: 'Entregas não alocadas',
            to: 'nexus-sales-unallocated',
          },
          {
            title: 'Transferência de carteira',
            to: 'nexus-sales-transfer',
          },
          {
            title: 'Adimplência',
            to: 'utilities-compliance',
          },
        ],
      },
    ],
  },
]

const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
const hierarchicalProfile = userProfile?.hierarchical_profile

if (hierarchicalProfile === 'nivel_2') {
  sigma[sigma.length - 1].children[3].children.push({
    title: 'Contratos estornados',
    to: 'reserved-contracts',
  })
}

if (hierarchicalProfile === 'nivel_2' || hierarchicalProfile === 'nivel_3') {
  sigma.push({
    title: 'PARÂMETROS',
    icon: mdiTuneVariant,
    children: [
      {
        title: 'Comissões',
        to: 'params-commissions',
      },
      {
        title: 'Bonificações',
        to: 'params-bonification',
      },
      {
        title: 'Metas',
        to: 'sigma-params-goal',
      },
    ],
  })
}

// if (hierarchicalProfile === 'nivel_2' || hierarchicalProfile === 'nivel_3') {
//   sigma.push({
//     title: 'UTILITÁRIOS',
//     icon: mdiTuneVariant,
//     children: [
//       {
//         title: 'Adimplência',
//         to: 'utilities-compliance',
//       },
//     ],
//   })
// }

export default sigma
